<template>
  <!--begin::Basic info-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div class="card-header border-0 bg-default" style="display:none">
      <!--begin::Card title-->
      <div class="card-title m-0 d-flex justify-content-between w-100">
        <!--begin::Navs-->
        <div class="d-flex overflow-auto h-55px mb-5">
          <ul
            class="
              nav nav-stretch nav-line-tabs nav-line-tabs-2x
              border-transparent
              fs-5
              fw-bolder
              flex-nowrap
            "
          >
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                to="/person-map/pro"
                class="nav-link text-active-white text-warning me-6"
                active-class="active"
              >
                Pro
              </router-link>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                class="nav-link text-active-white text-warning me-6"
                to="/person-map/kontra"
                active-class="active"
              >
                Kontra
              </router-link>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                class="nav-link text-active-white text-warning me-6"
                to="/person-map/netral"
                active-class="active"
              >
                Netral
              </router-link>
            </li>
            <!--end::Nav item-->
          </ul>
        </div>
        <!--begin::Navs-->
      </div>
      <!--end::Card title-->
    </div>
    <!--end::Card header-->
    <router-view />
  </div>
  <!--end::Basic info-->
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const store = useStore();
  },
});
</script>
